<template>
  <div class="component-documents">
    <div class="container">
      <div class="subject">게시판</div>
      <div class="content">
        <div class="folders">
          <div class="folder" :class="{ opened: state.openedIdxes[idx] }" v-for="(f, idx) in defines.faqs" :key="idx">
            <div class="head" @click="toggle(idx)">
              <div class="wrapper">
                <div class="inner">
                  <div class="title">
                    <span class="text">{{ f.question }}</span>
                  </div>
                  <div class="desc">{{ f.desc }}</div>
                  <i class="fa fa-angle-down"></i>
                </div>
              </div>
            </div>
            <div class="body" v-if="state.openedIdxes[idx]">
              <div>{{ f.answer }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import defines from "../defines.json";

export default {
  setup() {
    const store = useStore();
    const state = reactive({
      openedIdxes: [],
    });

    const toggle = (idx) => {
      state.openedIdxes[idx] = !state.openedIdxes[idx];
    };

    return { store, state, defines, toggle };
  },
};
</script>
<style lang="scss" scoped>
.component-documents {
  background: #f7f7f7;
  padding: 25px 0;

  .container {
    > .subject {
      font-size: 18px;
      margin-bottom: 17.5px;
      font-weight: bold;
      padding: 0 5px;
    }

    .folders {
      margin-bottom: 35px;

      .folder {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        font-size: 15px;

        .head {
          background: none;
          padding: 0;
          border: 0;

          > .wrapper {
            cursor: pointer;
            padding: 15px 15px;
            color: inherit;
            text-decoration: none;

            > .inner {
              position: relative;
              padding: 5px 35px 5px 10px;

              .title {
                .text {
                  margin-right: 6px;
                  vertical-align: middle;
                }
              }

              > i {
                position: absolute;
                top: 7.5px;
                right: 0;
                padding: 0 10px;
                font-size: 20px;
                transition: transform 0.25s;
              }
            }
          }
        }

        .body > div {
          padding: 5px 25px 22.5px 25px;
        }

        &:not(:last-child) {
          margin-bottom: -1px;
        }

        &.opened {
          .head > .wrapper > .inner {
            .title .text {
              font-weight: bold;
            }

            > i {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
    }
  }
}
</style>
